.loading {
  height: 700px;

  .container {
    margin-top: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: block;

    .topography {
      margin-bottom: 10px;
      color: #0b1421;
      font-size: 18px;
      line-height: 25.2px;
    }
  }
}
