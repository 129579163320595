.lender-details {
  margin-left: auto;
  margin-right: auto;
}

.body {
  margin: 0;
  background-color: #f7f8fc;
  padding-bottom: 96px;
}

.PolicyDetailsCard {
  background-color: #f7f8fc;
  padding-left: 56px;
  padding-right: 56px;
  padding-bottom: 30px;
  font-family: RT Raleway, sans-serif;
  max-width: 1328px;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;

  .hidden {
    display: none;
  }
}

.active,
.inactive,
.canceled,
.cancelled {
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 8px;
}

.active {
  background-color: #b3f9ce;
}

.inactive,
.canceled,
.cancelled {
  background-color: #feb3b3;
}

.PolicyDetailsCard-title {
  line-height: 56px;
  font-size: 44px;
  font-family: RT Raleway, sans-serif;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
  font-weight: 500;
}

.PolicyDetailsCard-subtitle {
  font-family: RT Raleway, sans-serif;
  font-size: 23px;
  line-height: 32px;
  letter-spacing: 0.6px;
}

.PolicyDetailsCard-header {
  background-color: #d3e6fa;
  border-radius: 8px 8px 0 0;
}

.PolicyDetailsCard-box {
  display: flex;
}

.PolicyDetailsCard-main {
  box-shadow: inset 0 0 0 1px #e3e5ee;
  width: 100%;
  border-radius: 8px;
  margin-top: 32px;
  filter: drop-shadow(0 4px 8px rgba(25, 30, 43, 0.08));
}

.mort-modal-background {
  background-color: #f7f8fc;

  .MuiInputBase-root,
  .MuiAutocomplete-root {
    border-bottom: none;
  }

  .MuiInputBase-root:hover {
    color: #4d5160;
  }
}

.MuiPopperUnstyled-root {
  font-family: RT Raleway, sans-serif;
}

.PolicyDetailsCard-details-message {
  font-family: RT Raleway, sans-serif;
  font-weight: 400;
}

.PolicyDetailsCard-delete-mortgagee-message {
  font-family: Cabin, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  span {
    margin-top: 10px;
    display: block;
  }
}

.PolicyDetailsCard-delete-mortgagee-buttons {
  margin-top: 8px;
  margin-bottom: 28px;
  justify-content: center !important;

  button {
    width: 182px;
    margin: 0 10px;
  }
}

.PolicyDetailsCard-switch-message {
  font-family: RT Raleway, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 36px;
}

.PolicyDetailsCard-policy-image {
  margin-left: 29px;
  margin-top: 33.33px;
  margin-bottom: 33.33px;
}

.PolicyDetailsCard-header-text {
  align-self: center;
  margin-left: 20.4px;
  width: 89%;
}

.PolicyDetailsCard-policy-number {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.PolicyDetailsCard-policy-period {
  font-family: Cabin, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-top: 10px;
}

.PolicyDetailsCard-policy-status {
  display: flex;
  gap: 8px;
  align-self: center;
  margin-right: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.PolicyDetailsCard-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  padding-top: 1px;
}

.PolicyDetailsCard-box-details {
  margin-right: 29px;
  margin-left: 29px;
  line-height: 24px;
  font-size: 18px;
  padding: 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.PolicyDetailsCard-details-section .title,
.PolicyDetailsCard-details-section-vert .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}

.PolicyDetailsCard-details-section img,
.PolicyDetailsCard-details-section-vert img {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.PolicyDetailsCard-details-section-vert img.button-icon {
  margin-bottom: 4px;
}

.buttonGroup {
  flex-direction: column;
  display: flex;
  width: fit-content;
  gap: 8px;
}

.charges-details {
  display: flex;
  justify-content: space-between;
}

.PolicyDetailsCard-details-section {
  display: flex;
  align-items: flex-start;
  gap: 11px;
  margin-bottom: 28px;

  .policy-details-card-download-available-doc {
    width: 95%;
    padding: 16px;
    background-color: #f7f8fc;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .policy-details-card-request-email-fax {
    padding: 25px 20px;
    border-top: 2px solid #e7e8e9;

    button {
      border: 0;
      font-size: 18px;
      font-weight: 600;
      color: #115dc5;
      background-color: #ffffff;
    }
  }
}

.PolicyDetailsCard-details-section-vert {
  display: flex;
  gap: 11px;
  align-items: flex-start;
  width: 100%;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0;
}

.mort-card-add {
  min-height: 265px;

  button {
    flex-grow: 1;
  }
}

.mort-card {
  background: #ffffff;
  box-shadow: 15.8186px 15.2896px 67px rgba(0, 0, 0, 0.05), 8.00816px 7.74036px 29.2078px rgba(0, 0, 0, 0.03375), 3.16372px 3.05792px 10.8875px rgba(0, 0, 0, 0.025), 0.692064px 0.66892px 3.87344px rgba(0, 0, 0, 0.01625);
  border-radius: 8px;
  color: #4d5160;
  max-width: 240px;
  min-width: 240px;
  display: flex;
  flex-direction: column;

  .lien {
    margin-left: 16px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: row;

    > span {
      padding: 16px 0;
      flex-grow: 1;
    }
  }

  .lien-holder {
    margin-left: 16px;
    margin-right: 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .lien-address {
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 14px;
    font-size: 16px;
    line-height: 120%;
    flex-grow: 1;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      padding: 8px 17px;
    }
  }
}

.lender-buttons-title {
  font-family: RT Raleway, sans-serif;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.6px;
  margin: 20px 0;
}

.lien-action {
  &button {
    background-color: #ffffff;
    color: #4d5160;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .button-title {
    font-size: 18px;
    line-height: 24px;
  }

  .button-text {
    font-size: 12px;
    line-height: 24px;
  }

  img {
    margin-left: 32px;
  }
}

.lien-action-group {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-left: 20px;
}

.lien-action-text {
  text-align: left;
}

button.MuiButton-outlined:not(.Mui-disabled) {
  box-shadow: 0 4px 8px rgba(25, 30, 43, 0.08);
  background-color: #ffffff;
}

header {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.PolicyDetailsCard-documents-section {
  width: 100%;

  .title {
    margin-bottom: 16px;
  }

  input {
    margin-right: 56px;
  }

  button.right {
    float: right;
  }
}

.PolicyDetailsCard-checkbox-group {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.Dialog {
  width: 100%;
  max-width: 780px;
}

.white-background {
  background-color: #ffffff;
}

.mort-cards-collection {
  width: 100%;
}

button {
  &.PolicyDetailsCard-button {
    padding: 6px 12px;
    border-radius: 25px;
    line-height: 16px;
    font-size: 14px;
    width: fit-content;
  }

  &.close-icon {
    padding: 0;

    img {
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }
}

.PolicyDetailsCard-details-info-message {
  background-color: #e3e5ee !important;
  margin-top: 14px;
}

.PolicyDetailsCard-start-over {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.PolicyDetailsCard-details-info-message-no-mortgagee {
  font-family: Cabin, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.4px;
  margin-top: 21px;

  button {
    color: #4d5160 !important;
    padding: 0;
    text-decoration: underline;
  }
}

.modal.mort-modal.deleteConfirmationModal {
  div[aria-describedby='csaa-modal-title'] {
    width: 464px;
  }
}