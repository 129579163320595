.session-inactive-modal {
  text-align: center;

  .modal-content {
    .baloon-image {
      margin: 40px 0;
      position: relative;
      height: 130px;

      img {
        position: absolute;
        z-index: 2;

        &.balloon {
          right: calc(50% - 45px);
        }

        &.top-cloud {
          right: 50%;
          top: 9px;
          z-index: 3;
        }

        &.bottom-cloud {
          left: 50%;
          top: 47px;
          z-index: 1;
        }
      }
    }

    .modal-content-text {
      margin: 0 68px;
      font-family: Cabin, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0;
      text-align: center;
    }
  }

  button.start-over {
    margin: 40px 0 80px 0;
  }
}
