.maintenance {
  .title {
    font-size: 44px !important;
    line-height: 61.6px !important;
  }

  .desc {
    font-size: 18px;
    line-height: 25.2px;
  }

  .form {
    padding: 150px 48px;
  }
}
