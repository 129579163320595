.flexForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.PolicyDetailsCard-details-section {
  width: 100%;
}

.DocumentDetailsCard {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  font-family: RT Raleway, sans-serif;
}

.formDirections {
  font-size: 14px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
}

.buttonGroup {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-bottom: 40px;
  margin-top: 24px;
}

.DocumentDetailsCard-title-middle {
  font-size: 23px;
  line-height: 32px;
  margin: 8px 0;
  letter-spacing: 0.6px;
  font-weight: 500;
}

.DocumentDetailsCard-button-info {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  width: 100%;
}

.DocumentDetailsCard .MuiInputBase-root,
.DocumentDetailsCard .MuiInputBase-root:not(.Mui-error):not(.Mui-disabled),
.DocumentDetailsCard .MuiAutocomplete-root.Mui-focused,
.DocumentDetailsCard .MuiAutocomplete-root:hover {
  border-bottom: none;
  width: 100%;
}

.DocumentDetailsCard .MuiInputBase-root:before {
  border-bottom: none;
}
