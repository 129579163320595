.lender-form {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  font-family: Cabin, sans-serif;
}

.lender-form .container {
  display: flex;
}

.lender-form .image {
  max-width: 815px;
  float: left;
}

.lender-form .form {
  max-width: 624px;
  float: right;
  background-color: #f7f8fc;
}

.lender-form .form .inner {
  margin: 48px 51px 48px 0;
}

.lender-form .form .title {
  line-height: 56px;
  font-size: 44px;
  font-family: RT Raleway, sans-serif;
  letter-spacing: 0.6px;
  font-weight: bold;
  margin-bottom: 16px;
}

.lender-form .form .desc {
  font-size: 23px;
  font-family: RT Raleway, sans-serif;
  line-height: 32px;
  letter-spacing: 0.6px;
  margin-bottom: 24px;
}

.lender-form .form .form-text {
  font-size: 28px;
  font-family: RT Raleway, sans-serif;
  line-height: 40px;
  letter-spacing: 0.6px;
  margin-bottom: 16px;
  font-weight: bold;
}

.lender-form .form .hidden {
  display: none;
}

.lender-form .form .full {
  width: 100%;
}

.lender-form .form .no-shadow,
.lender-form .form .MuiInputBase-root,
.lender-form .form .MuiInputBase-root:not(.Mui-error):not(.Mui-disabled),
.lender-form .form .MuiAutocomplete-root.Mui-focused,
.lender-form .form .MuiAutocomplete-root:hover {
  border-bottom: none;
}

.lender-form .form .MuiTextField-root {
  margin-bottom: 8px;
}

.lender-form .form .MuiFilledInput-root:before, .lender-form .form .MuiFilledInput-root:hover, .lender-form .form .MuiFilledInput-root.Mui-focused {
  border-bottom: none;
}

.lender-form .form .split {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.lender-form .form .split .split-item {
  width: 49%;
  margin-left: 0;
  margin-right: 0;
}

.MuiAutocomplete-popper .drop-down-item {
  font-family: Cabin, sans-serif;
  font-size: 18px;
  letter-spacing: 0.4px;
}

.lender-form .links, .lender-details .links {
  max-width: 304px;
  text-align: center;
  font-size: 18px;
  line-height: 25.2px;
  font-family: RT Raleway, sans-serif;
  margin-top: 26px;
  margin-bottom: 23px;
  font-weight: bold;
}

.lender-form .links a:first-of-type,
.lender-details .links a:first-of-type {
  margin-right: 16px;
}

.lender-form .links a:nth-of-type(2),
.lender-details .links a:nth-of-type(2) {
  margin-left: 16px;
}

.lender-form .links p,
.lender-details .links p {
  font-size: 12px;
  line-height: 16px;
  font-family: Cabin, sans-serif;
  font-weight: normal;
}

.lender-form .form .MuiButton-root {
  margin-top: 30px;
  float: right;
  margin-bottom: 22px;
  margin-right: 29px;
}

.lender-form .lender-error {
  display: flex;
  border: 2px solid #d52b1e;
  background-color: #ffe6e6;
  border-radius: 8px;
  line-height: 130%;
  max-width: 1312px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 22px;
  justify-content: space-between;
}

.lender-form .error-details {
  display: flex;
}

.lender-form .lender-error .error-icon {
  margin-left: 16px;
  margin-right: 8px;
}

.lender-form .lender-error .error-close {
  margin-right: 32px;
}

.lender-form .error-text {
  margin-top: 16px;
  margin-bottom: 16px;
}

.lender-form .error-text p {
  margin-top: 0;
  margin-bottom: 0;
}

.recaptcha-error {
  color: #d52b1e;
  margin-left: 16px !important;
  margin-top: 4px !important;
}

.PolicyLookupCard-error-message {
  margin-bottom: 15px;

  .MuiAlert-icon,
  .MuiAlert-action {
    align-items: center;
  }
}