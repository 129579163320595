.mort-modal {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;

  h5 {
    font-family: RT Raleway, sans-serif;
    font-size: 28px !important;
    bottom: 30px;
    position: relative;
  }
}
