.Success-image {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.Success-alert {
  background-color: #f2fff7;
  border: 2px solid #00844d;
  border-radius: 8px;
  font-family: RT Raleway, sans-serif;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 17px;
}

.alert-icon {
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.success-message {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
}
