.modal {
  div[role='dialog'] {
    border-radius: 16px;
    width: 584px;
    overflow: hidden;

    .modal-content {
      overflow-y: auto;

      .modal-header {
        min-height: 80px;

        .icon-container {
          text-align: right;

          .modal-close-icon {
            margin-top: 30px;
            z-index: 1;
          }
        }

        h5 {
          font-size: 24px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: 0;
          text-align: center;
        }
      }

    }
  }
}
