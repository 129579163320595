.title-middle {
  font-size: 28px;
  line-height: 40px;
  font-family: RT Raleway, sans-serif;
  font-weight: bold;
}

.review-results {
  padding: 14px 24px;
  border-radius: 8px;
  border: 1px solid #e3e5ee;
}

.review-section {
  border-bottom: 1px solid #e3e5ee;
  margin-top: 8px;
}

.review-section:last-of-type {
  border-bottom: none;
}

.review-field {
  margin-bottom: 8px;
}
