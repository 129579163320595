.body {
  margin: 0;
  background-color: #f7f8fc;
  padding-bottom: 96px;
}

.otvcCard {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  font-family: RT Raleway, sans-serif;
  padding-top: 24px;
}

.title {
  line-height: 56px;
  font-size: 44px;
  font-family: RT Raleway, sans-serif;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
  font-weight: 500;
}

.main {
  box-shadow: inset 0 0 0 1px #e3e5ee;
  width: 100%;
  border-radius: 8px;
  margin-top: 32px;
  filter: drop-shadow(0 4px 8px rgba(25, 30, 43, 0.08));
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 8px;
  padding-top: 1px;
}

.box {
  margin-right: 29px;
  margin-left: 29px;
  line-height: 24px;
  font-size: 18px;
  padding: 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.iconShieldContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.instructions {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  width: 100%;
  margin-top: 24px;
}

.codeInputContainer {
  width: 327px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 56px;
}

.codeInput {
  input {
    color: #373b49;
    text-align: center;
    font-size: 28px;
    font-family: RT Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0.6px;
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }

  input::placeholder {
    opacity: 1;
  }
}

.resendButton {
  padding: 2px !important;
}

.resendInfoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-top: 23px;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 92px;
}

.exceededAttemptsInfo {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-top: 24px;
}
